<template>
  <v-container class="redeem-page">
    <div class="d-flex justify-space-between align-center rem-md-24 rem-6 primary--text mb-mb-14 mb-10">
      <img class="can-click" src="@/assets/img/icon-arrow-left.png" alt="arrow-left" @click="$router.go(-1)">
      {{ $t('redeemTitle') }}
      <div></div>
    </div>

    <v-row class="product-list" :justify="list.length < 4 ? 'center' : 'start'">
      <v-col cols="6" sm="4" md="3" class="prudoct-wrap" v-for="(item, i) in list" :key="i">
        <div class="product-img" :style="{backgroundImage: `url(${item.image})`}"></div>
        <div class="d-flex flex-column">
          <div class="rem-md-5 rem-0 mb-md-4 mb-3">{{ item.name }}</div>
          <div class="d-flex justify-space-between align-center">
            <span class="rem-md-6 rem-1 font-weight-black">{{ item.price.toLocaleString() }} <span class="d-inline-block">鈦金點</span></span>
            <redeemBtn @clickBtn="redeemItem = item; redeemShow = true"></redeemBtn>
          </div>
        </div>
      </v-col>
    </v-row>

    <!-- redeem dialog -->
    <v-dialog
      class="redeem-dialog"
      v-model="redeemShow"
      width="500"
    >
      <v-card
        class="redeem-dialog-content d-flex flex-column align-center"
        rounded="xl"
        color="#EFEBEB"
      >
        <div class="rem-md-22 rem-13 mb-7 darkGrey--text mb-6 mt-7">確認是否兌換</div>

        <img src="@/assets/img/icon-check.png" alt="icon-check">

        <v-form class="w-100 px-7" ref="form" lazy-validation>
          <v-checkbox
            class="primary--text mx-auto"
            style="width: fit-content;"
            v-model="is18"
            :label="'我確認我已滿１８歲'"
          ></v-checkbox>
        </v-form>

        <div class="w-100">
          <div class="dialog-btn py-7 text-center can-click rem-md-15 rem-6" @click="send()">{{ $t('confirm') }}</div>
          <div class="dialog-btn py-7 text-center can-click rem-md-15 rem-6" @click="is18 = false; redeemShow = false">{{ $t('cancel') }}</div>
        </div>
      </v-card>
    </v-dialog>

    <!-- 18+ warning -->
    <v-dialog
      class="warning-18"
      v-model="warningShow"
      width="365"
      persistent
    >
      <v-card class="warning-18-content d-flex flex-column align-center py-7 px-4" rounded="xl">
        <div class="rem-12 font-weight-bold mb-7">聲明條款</div>
        <div class="text-drunk font-weight-bold rem-24">嚴禁酒駕</div>

        <img class="my-6" src="@/assets/img/warning-18.png" alt="18+warning" width="100px">

        <p class="pre-wrap text-center font-weight-bold rem-8 mb-10" v-text="`酒類飲品僅提供18歲以上\n並同意下列條款之成年人瀏覽、兌換\n\n在您進入兌換頁面之前\n請先確認您已經年滿18歲`"></p>

        <div class="d-flex justify-space-between mb-5 w-100">
          <redeemBtn class="confirm-btn" btnText="我已滿18歲" @clickBtn="warningShow = false"></redeemBtn>
          <redeemBtn class="confirm-btn" color="#DB3646" btnText="我未滿18歲" @clickBtn="$router.push({name: 'Home', params: {lang: $store.state.locale}})"></redeemBtn>
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import redeemBtn from '@/components/redeemBtn'
export default {
  name: "Redeem",
  data() {
    return {
      list: [],
      warningShow: false,
      is18: false,
      redeemShow: false,
      redeemItem: null,
    }
  },
  components: {
    redeemBtn,
  },
  methods: {
    async send() {
      if (this.is18){
        if (this.$store.state.token){
          if (this.$store.state.userInfo.balance >= this.redeemItem.price){
            let result = await this.$store.dispatch('redeemProduct', {
              product_id: this.redeemItem.id,
              amount: 1
            })
            if (result.status === 1003){
              this.$toasted.show('兌換成功')
              this.$router.push({name: 'Order', params: {lang: this.$store.state.locale}})
            }else{
              this.$toasted.error('兌換失敗')
            }
          }else{
            this.$toasted.error('餘額不足')
          }
        }else{
          this.$toasted.error('請先登入')
        }
      }else{
        this.$toasted.error('請勾選確認')
      }
    }
  },
  async mounted() {
    this.warningShow = true
    try {
      let result = await this.$store.dispatch('getProductList')
      console.log('result', result)
      if (result.status === 1001){
        this.list = result.data || []
      }
    }catch (error){
      console.log('error', error)
    }
  }
}
</script>

<style lang="scss">
.redeem-page {
  .product-list {
    width: 85%;
    margin: 0 auto 100px;
    @include dai_vuetify_md {
      width: 90%;
    }
    @include dai_vuetify_sm {
      width: 100%;
    }
    .prudoct-wrap {
      .product-img {
        width: 100%;
        padding-bottom: 100%;
        background-size: cover;
        background-position: center;
      }
    }
  }
}
.warning-18-content {
  .text-drunk {
    letter-spacing: .36em;
  }
  .confirm-btn {
    // width: 163px;
    width: 48%;
    height: 49px;
    font-size: 21px;
  }
}
.v-dialog {
  border-radius: 24px !important;
  &::-webkit-scrollbar { display: none !important; }
}
.v-input--checkbox {
  label {
    color: var(--v-primary-base) !important;
  }
}
.dialog-btn{
  width: 50%;
  display: inline-block;
  border-top: 1px solid #ABABAB;
  letter-spacing: .5em;
}
.dialog-btn:first-child{
  border-right: 1px solid #ABABAB;
}
.redeem-types {
  font-size: 18px;
  color: black;
}
.v-label {
  font-size: 21px !important;
}
</style>